import Navbar from "../../src/components/Navbar";
import GameTest from "../components/GameTest";

const Game = () => {
  return (
    <>
      <Navbar />
      <GameTest />
    </>
  );
};

export default Game;
