import React from "react";
import Pokemon from "./Pokemon";

const Pokedex = () => {
  return (
    <>
      <Pokemon />
    </>
  );
};

export default Pokedex;
