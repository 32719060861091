const Types = {
    bug: '#72e33f',
    dragon: '#1a90cf',
    fairy: '#fd32e9',
    fire: '#fd4924',
    ghost: '#7b15a3',
    ground: '#f7de00',
    normal: '#a4acaf',
    pyschic: '#f367eb',
    steel: '#09eb00',
    dark: '#707070',
    electric: '#eed500',
    fighting: '#d56723',
    flying: '#3dc7ef',
    grass: '#9bcc50',
    ice: '#51c4e7',
    poison: '#b9114f',
    rock: '#a38c21',
    water: '#4592c4',
}

export default Types;