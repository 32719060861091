import React, { useState } from "react";
import axios from "axios";
import Types from "./Types";

const Search = () => {
  const [pokemon, setPokemon] = useState("pi");
  const [pokemonData, setPokemonData] = useState([]);
  const [pokemonType, setPokemonType] = useState(" ");

  const handleChange = (e) => {
    setPokemon(e.target.value.toLowerCase());
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getPokemon();
  };

  const getPokemon = async () => {
    const toArray = [];
    try {
      const url = `https://pokeapi.co/api/v2/pokemon/${pokemon}`;
      const res = await axios.get(url);
      toArray.push(res.data);
      setPokemonType(res.data.types[0].type.name);
      setPokemonData(toArray);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            onChange={handleChange}
            placeholder="Search Pokemon"
          />
        </label>
      </form>
      {pokemonData.map((data) => {
        return (
          <div className="Card__pokemon Card__pokemon--search"  onClick={() => (`/${data.id}`)}>
            <div className="Card__img">
              <div className="circle"></div>
              <img
                className="Card__image"
                src={data.sprites.front_default}
                alt={data.name}
              />
            </div>
            <div className="Card__name">{data.name}</div>
            <div className="Card__types">
              {data.types.map((type) => {
                return (
                  <div
                    className="Card__type"
                    style={{ backgroundColor: Types[type.type.name] }}
                  >
                    {pokemonType}
                  </div>
                );
              })}
            </div>

            <div className="Card__info">
              <div className="Card__data Card__data--weight">
                <p className="title">Weight</p>
                <p>{data.weight}</p>
              </div>
              <div className="Card__data Card__data--weight">
                <p className="title">Height</p>
                <p>{data.height}</p>
              </div>
              <div className="Card__data Card__data--ability">
                <p className="title">Ability</p>
                <p>{data.abilities[0].ability.name}</p>
              </div>
            </div>
          </div>
        );
      })}
      
    </>
  );

  
};

export default Search;
