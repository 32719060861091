const Footer = () => {
  return (
    <div className="footer">
      &copy; {new Date().getFullYear()} Yuliia Ferreira | Robert Bolstad from{" "}
      <a href="https://pokeapi.co/"> https://pokeapi.co</a>
    </div>
  );
};

export default Footer;
