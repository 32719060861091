import Tilt from "react-tilt";

const Heroimage = () => {
  return (
    <>
      <div className="card-container">
        <div className="card">
          <div className="pokemon">
            <div className="circle"></div>

            <Tilt className="cards-form" options={{ speed: 600, max: 80 }}>
              <img src="../images/pokeball.png" alt="pokeball" />
            </Tilt>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heroimage;
