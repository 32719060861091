import Navbar from "../../src/components/Navbar";
import Search from "../components/Search";
import Heroimage from "../components/Heroimage";
import Background from "../components/Background";
import Pokedex from "../components/Pokedex";
import Footer from "../components/Footer";

const Homepage = () => {
  return (
    <>
      <Navbar />
      <Heroimage />
      <Search />
      <Background />
      <h1 className="pokedex">Pokedex</h1>
      <Pokedex />
      <Footer />
    </>
  );
};

export default Homepage;
