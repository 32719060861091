let dino = document.querySelector(".dino");

const GameTest = () => {
  function jump() {
    if (!dino.classList.contains("jump")) {
      dino.classList.add("jump");
    }
    setTimeout(function () {
      dino.classList.remove("jump");
    }, 500);
  }
  document.body.onkeydown = function (e) {
    if (e.key === 32) {
      jump();
    }
  };

  return (
    <>
      <div className="game">
        <div className="dino"></div>
        <div className="cactus"></div>
      </div>
    </>
  );
};

export default GameTest;
